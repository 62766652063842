<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Редактирование аккаунта"
        update
        url-update="/user/update/:id"
        :url-back="`/user?role=${queryRole}`"
        action-one="user/one"
    >
        <user-form :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import UserForm from '@/components/forms/UserForm.vue'
export default {
    name: 'UserUpdate',
    components: { FormView, UserForm },
    computed: {
        queryRole () {
            return this.$route.query.role === 'undefined' ? '' : this.$route.query.role;
        }
    }
}
</script>